import $ from "jquery";
require("jquery-ui");
import "../../../node_modules/jquery-ui/ui/widgets/autocomplete.js"
import "../../css/small.scss"
import "../../css/med.scss"
import "../../css/metros/metro.scss"

class Search {
    getSearch() {
        console.log();
        $("#search-input").autocomplete({
            source: function(request, response) {
                $.ajax({
                    url: "/concretes/api/tags",
                    dataType: "json",
                    data: { term: request.term },
                    success: function(data) {
                        response($.map(data, function(item) {
                            return {
                                label: item.prod_name,
                                id: item.prod_id,
                                title: item.prod_name,
                                url: item.prod_url
                            };
                        }));
                    }
                });
            },
            minLength: 2,
            select: (event, ui) => {
                let titles = ui.item.label;
                let spaceTrim = titles.split(' ').join('-');
                let periodTrim = spaceTrim.split('.').join('-');
                let title = periodTrim;
                window.location.href = '/bangkok/' + ui.item.url + '/' + title;
            }
        });
    }
}
export { Search }