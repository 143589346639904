export class NavLeft {
    async metro(){
        const metroMenus = document.querySelectorAll('.mains #metro-menu')
        
        let brands = await this.getBrand()
        
        for (const nav of metroMenus) {
        
        const ulBrand = document.createElement('ul')
        ulBrand.classList.add('metro')
        nav.append(ulBrand)

        let listsHtml = '<div class="SiteHeaderDropPanelFirst overflow-visible">'
        for (let i = 0; i < brands.length; i++) {
            const brand = brands[i];
            
            listsHtml += `
                <li data-id="${brand.brand_id}" id="brand_${brand.brand_id}" class="nav-item dropdown">
                    <a class="flex icon-link nav-link" href="/metros/brands/${brand.brand_id}/${brand.brand_name}">
                        <div class="nav-img-container">
                            <div class="nav-img-box bg-sidebar">
                                <img src="/assets/images/contents/${brand.brand_logo}" alt="">
                            </div>
                        </div>
                        <div class="nav-text mar2">${brand.brand_name}</div>
                    </a>
                <label for="drop-${brand.brand_id}0" class="toggleProduct"><i class="bi bi-chevron-down h4"></i></label>
                <input type="checkbox" id="drop-${brand.brand_id}0">
                 `
                    await this.mixByBrand(brand.brand_id,brand.nav_img)
            listsHtml +=`</li>`
        }
            listsHtml +='</div>' ;
        ulBrand.innerHTML = listsHtml
        }
    }

    async mixByBrand(brand,nav){
        fetch(`/metros/api/byBrand/${brand}`).then(response=>response.json())
            .then(response=>{
                
                const brandnavs = document.querySelectorAll(`.metro #brand_${brand}`)
                
                
                brandnavs.forEach(brand=>{
                    const ul = document.createElement('ul')
                    const ulMix = document.createElement('ul')
                    const divContainer = document.createElement('div')
                    const imgNav = document.createElement('img')

                    imgNav.src=`/assets/images/contents/${nav}`
                    imgNav.classList.add('nav_browser','img-fluid')
                    divContainer.classList.add('SiteHeaderDropPanel','SiteHeaderDropPanelSecond','super_cat', 'nav_browser','mt-4')
                    ulMix.classList.add('flyout_left','dropdown_menu');
                    brand.appendChild(ul)
                    ul.append(divContainer)
                    divContainer.append(imgNav)
                    divContainer.append(ulMix)
                for (let i = 0; i < response.length; i++) {
                    const prod = response[i];
                    const li = document.createElement('li')
                    li.classList.add('nav-item');
                    const anchor = document.createElement('a')
                    
                    anchor.classList.add('flex','nav-link','icon-link','dropdown-item')
                    anchor.href='/metros/'+prod.prod_url+'/'+prod.prod_name.replace(/[\s\.]/g,'-')
                    anchor.innerHTML = `
                        <div class="row justify-content-between align-items-center">
                            <div class="col-lg-2 col-md-2">
                                <img src="/assets/images/contents/${prod.prod_img}" alt="${prod.prod_name}" class="img-fluid">
                            </div>
                            <div class="col-lg-10 col-md-10">${prod.prod_name}</div>
                        </div>
                        `
                    li.append(anchor)
                    ulMix.append(li)
                }
                ulMix.insertAdjacentHTML('beforeend',`
                    <li class="nav-item">
                        <a href="/metros/brands/${response[0]?.brand_id}/${response[0]?.brand_name.replace(/[\s\.]/g,'-')}" title="ดูคอนกรีตผสมเสร็จ${response[0]?.brand_name} ทั้งหมด อื่น ๆ ">คอนกรีตผสมเสร็จ${response[0].brand_name} เพิ่มเติม</a>
                    </li>
                    `);
            });
                
            })
            
    }
    async pump(){
        const pumpMenus = document.querySelectorAll('.mains #pump-menu')
        const pumps = localStorage.getItem('pumpType') ? JSON.parse(localStorage.getItem('pumpType')) : []

        for (const navpump of pumpMenus) {
            const ulPumpType = document.createElement('ul')
            navpump.append(ulPumpType)

            let listsHtml = '<div class="SiteHeaderDropPanelFirst">'
            for (let i = 0; i < pumps.length; i++) {
                const pumpType = pumps[i];
                listsHtml += `
                <li data-id ="${pumpType.id}" id="pump_type_${pumpType.id}">
                    <a class="flex" href="/pumps/types/${pumpType.id}/${pumpType.title}" title="${pumpType.title}">
                        <div class="nav-img-container"><div class="nav-img-box bg-sidebar">
                            <img src="/assets/images/contents/${pumpType.image}" alt="${pumpType.title}" class="img-fluid">
                        </div></div>
                        <div class="nav-text mar2">${pumpType.title}</div>
                    </a>
                    <label for="drop-pump-type-menu-${pumpType.id}0" class="toggleProduct"><i class="fas fa-chevron-down"></i></label>
                    <input type="checkbox" id="drop-pump-type-menu-${pumpType.id}0">
                    `
                    await this.getPumpType(pumpType.id)
                listsHtml += `</li>`
            }
            listsHtml += `</div>`
            ulPumpType.innerHTML = listsHtml
            }
            
    }

    async getPumpType(type){
        fetch(`/metros/api/pumps/${type}`,{method:'GET'}).then(response=>response.json())
        .then(pumps=>{
            const items = document.querySelectorAll(`#pump_type_${type}`)
            items.forEach(item=>{
            const div = document.createElement('div')
            const ul = document.createElement('ul')
            const img = document.createElement('img')
            div.classList.add('SiteHeaderDropPanelSecond','position-relative','mt-4')
            img.classList.add()
            img.src = '/assets/images/contents/pumpconcrete_500x495.png'

            const ulpump = document.createElement('ul')
            ulpump.classList.add('flyout_left','w-100')
            ul.append(div)
            let anchorHtml = ''
            for (let i = 0; i < pumps.length; i++) {
                const pump = pumps[i];
                
                    const li = document.createElement('li')
                    li.classList.add('d-flex')
                    const anchor = document.createElement('a')

                    div.append(img)
                    div.append(ulpump)
                    anchorHtml = `<a href="/pumps/${pump.pump_url}/${pump.pump_name.replace(/[\s\.]/g,'-')}" class="row">
                                    <div class="col-lg-3 col-md-3">
                                        <img src="/assets/images/contents/${pump.pump_img}" alt="${pump.pump_name}">
                                    </div>
                                    <div class="col-lg-9 col-md-9">${pump.pump_name}</div>
                                </a>`
                    li.innerHTML = anchorHtml
                    ulpump.append(li)
                    item.append(ul)

            }
            });
        });
        
    }

    async getBrand(){
        try {
            const response = await fetch('/metros/api/allBrands')
            const brandJson = await response.json()            
            return brandJson;
        } catch (error) {
            console.error(error);
        }
    }

    async getPump(){
        try {
            const response = await fetch('/metros/api/pumps',{method:'GET'})
            const json = await response.json()
            
            localStorage.setItem('pumps',JSON.stringify(json))
            const type = json.map((item)=>{    
                return {
                    id:item.type_id,
                    title:item.pump_type_name,
                    image:item.pump_type_img
                }
            })
            const ids = type.map(({title})=>title)
            const types = type.filter(({title},index)=>!ids.includes(title,index+1))

            localStorage.setItem('pumpType',JSON.stringify(types))
        } catch (error) {
            console.error(error);
        }
    }

    async strenght(){
        const strenghts = await this.getStrenght()
        const strenghtMenu = document.querySelectorAll('.mains #strenght-menu')

        for (const menu of strenghtMenu) {
        const div = document.createElement('div')
        const ul = document.createElement('ul')
        ul.classList.add('mb-4')
        div.classList.add('SiteHeaderDropPanelFirst')
        menu.append(ul)
        ul.append(div)
        let lihtml = ''
        for (let i = 0; i < strenghts.length; i++) {
            const strenght = strenghts[i];
            lihtml += `
            <li class="nav-item dropdown">
                <a class="flex" href="/bangkok/strengths/กำลังอัด/${strenght.prod_strenght}" title="คอนกรีตรับแรงอัด ที่ ${strenght.prod_strenght} กก.ต่อตร.ซม. ที่ 28 วัน"
                    class="icon-link nav-link">
                    <i class="fas fa-weight-hanging h4"></i>
                    <div class="mar2 nav-text">คอนกรีตกำลังอัด ${strenght.prod_strenght} ksc.cube</div>
                </a>
                <input type="checkbox" id="drop-st">
                <label for="drop-st" class="toggleProduct"><i class="fas fa-chevron-right"></i></label>
                <ul class="nav dropdown-menu dropdown-menu-right mt-4">
                    <div class="SiteHeaderDropPanelSecond w-100">
                `
            lihtml += await this.showByStrength(strenght.prod_strenght)
            lihtml += `</div>
                </ul> `
        }
        lihtml += '</li>'
        div.innerHTML = lihtml
        }
    }
    async getStrenght(){
        try{
        const response = await fetch('/metros/api/strenghts')
        const strenghts = await response.json()
        return strenghts
        }catch(error){
            console.log(error);;
        }
    }
    async getByStrength(strength){
        try {
            const response = await fetch(`/metros/api/strengths/${strength}`)
            const json = await response.json()
            return json
        } catch (error) {
            console.error(error);
        }
    }
    async showByStrength(strength) {
        const products = await this.getByStrength(strength);
        let html = products.map(product => `
            <li class="nav-item">
                <a href="/bangkok/c/${product.prod_id}/${product.prod_name.replace(/[\s\.]/g,'-')}" class="nav-link icon-link">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-2 col-md-2">
                            <img src="/assets/images/contents/${product.prod_img}" alt="${product.prod_name}" class="img-fluid">
                        </div>
                    
                        <div class="col-lg-10 col-md-10">${product.prod_name}</div>
                    </div>
                </a>
            </li>
        `).join('');
    
        return html;
    }
                
}
